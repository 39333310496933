import { render, staticRenderFns } from "./Done.vue?vue&type=template&id=b4c81e4e&scoped=true&"
import script from "./Done.vue?vue&type=script&lang=javascript&"
export * from "./Done.vue?vue&type=script&lang=javascript&"
import style0 from "./Done.vue?vue&type=style&index=0&id=b4c81e4e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4c81e4e",
  null
  
)

export default component.exports