<!--
 * @Author: zhai
 * @Description: 完成
-->
<template>
    <div class="done-box">
        <p class="tit">资料已全部完成填写</p>
        <div class="done-tips">
            已提交的信息可通过【基础信息-个人信息表】查看
        </div>
        <!-- 保存按钮 -->
        <div class="operations-group">
            <el-button class="person-btn" @click="goPre">上一步</el-button>
            <!-- <el-button class="person-btn btn-blue" :loading="doneLoading" @click="done">确认提交</el-button> -->
            <el-button type="primary" :loading="doneLoading" @click="done">确认提交</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import { userSubmit } from "@/api/workfow.js";
export default {
    name: "Done",
    components: {},
    data() {
        return {
            doneLoading: false,
        };
    },
    created() {

    },
    methods: {
        // 完成并提交
        done() {
            this.$confirm(
                "提交后您将无法编辑，请再次确认",
                "确认提交您的个人信息？",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.doneLoading = true;
                    userSubmit({
                        orderId: this.$route.params.id,
                    }).then((res) => {
                        this.doneLoading = false;
                        if (res.code === 200) {
                            this.$message.success("提交成功！");
                            localStorage.setItem("stepEnd", 1);
                            localStorage.setItem("isList", 0);
                            this.$store.commit("user");
                            this.$router.replace({
                                name: "JumpPage",
                            });
                        } else {
                            this.$message.success(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        // 返回上一步
        goPre() {
            this.$router.replace({
                name: "ltep5",
            });
        },
    },
};
</script>

<style lang="less" scoped>
.done-box {
    width: 960px;
    margin: 50px auto 0;
    font-size: 14px;
    color: #303133;
    .tit {
        font-size: 20px;
        color: #303133;
        margin-bottom: 20px;
        margin-top: 100px;
    }
    .done-tips {
        font-size: 16px;
        color: #666;
    }
}
</style>
